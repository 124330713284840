<template>
    <div>
        <TransportationBatch :idx="idx" />
    </div>
</template>

<script>
import TransportationBatch from './TransportationBatch.vue'
    export default {
        components: {
            TransportationBatch,
        },
        data() {
            return {
                idx: 2
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>